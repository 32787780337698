<template>
  <div v-if="status" id="user-list">
    <customer-list v-if="this.userData.profile === '30' && $vuetify.breakpoint.xsOnly" :style-theme="false" />

    <!-- app drawer -->

    <!-- list filters -->
    <v-card rounded="xl" :color="$vuetify.breakpoint.xsOnly ? 'white' : 'thead-color'" :disabled="loading">
      <v-card-title class="text-h6" :class="$vuetify.breakpoint.xsOnly ? 'thead-color' : 'form-color'">
        <v-icon left>
          {{ icons.mdiTrayFull }}
        </v-icon>
        Ekstre
      </v-card-title>

      <v-row no-gutters>
        <v-col cols="12">
          <v-text-field
            v-model="search"
            class="rounded-0"
            label="Arama Terimi"
            placeholder="İsteğe Bağlı"
            persistent-placeholder
            rounded
            filled
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- table -->

      <v-data-table
        :headers="tableColumns"
        sort-by="head.OrderNumber"
        :sort-desc="true"
        :search="search"
        :loading="loading"
        :items="tableItems"
      >
        <template v-if="sumBalance > 0" v-slot:footer>
          <v-card color="primary" elevation="1" rounded="t-lg b-0">
            <v-card-title class="text-sm">
              <span class="white--text">Bakiye Toplamı </span> <v-spacer />
              <span class="white--text">{{ sumBalance | currency }}</span>
            </v-card-title>
          </v-card>
        </template>
        <template v-slot:item.RefNumber="{ item }">
          <v-chip label small>
            {{ item.RefNumber }}
          </v-chip>
        </template>
        <template v-slot:item.Loc_Debit="{ item }">
          <div class="text-caption error--text">
            {{ item.Loc_Debit | currency }}
          </div>
        </template>
        <template v-slot:item.Loc_Credit="{ item }">
          <div class="text-caption success--text">
            {{ item.Loc_Credit | currency }}
          </div>
        </template>
        <template v-slot:item.Loc_Balance="{ item }">
          <div class="text-caption primary--text">
            {{ item.Loc_Balance | currency }}
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
  <div v-else>
    <customer-list v-if="userData.profile === '30' && $vuetify.breakpoint.xsOnly" />
    <v-alert text outlined class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption" type="warning">
      Lütfen yukarıdan bir müşteri seçin
    </v-alert>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import axios from '@axios'
import {
  mdiAccountOutline,
  mdiCircle,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiMagnify,
  mdiTrayFull,
} from '@mdi/js'

export default {
  components: {
    CustomerList,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      status: false,
      sumBalance: 0,
      tableItems: [],
      tableColumns: [
        { text: 'Dokuman No', value: 'DocumentNumber' },
        { text: 'Referans No', value: 'RefNumber' },
        { text: 'Tipi', value: 'Type' },
        { text: 'Tarih', value: 'DocumentDate' },
        { text: 'Borç', value: 'Loc_Debit', align: 'end' },
        { text: 'Alacak', value: 'Loc_Credit', align: 'end' },
        { text: 'Bakiye', value: 'Loc_Balance', align: 'end' },
        { text: 'Vade Tarihi', value: 'DueDate' },
        { text: 'Ofis', value: 'OfficeDescription' },

        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      search: '',
      loading: false,

      icons: {
        mdiTrayFull,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiCircle,
        mdiMagnify,
        mdiAccountOutline,
      },
    }
  },

  watch: {
    '$store.state.plasiyerCustomer': function (newValue) {
      if (newValue) {
        this.status = true
        this.submitForm()
      }
    },
  },

  mounted() {
    this.submitForm()
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.profile === '30' && store.state.plasiyerCustomer === null) {
      this.status = false
    } else {
      this.status = true
    }
  },

  methods: {
    detailFn(item) {
      this.tableDetailItems = item
      this.dialog = true
    },
    async submitForm() {
      this.loading = true
      const params = new URLSearchParams()
      params.append('method', 'getExtract')
      axios
        .post('', params)
        .then(response => {
          if (!response.data.error) {
            return
          }
          this.tableItems = response.data.detail

          this.sumBalance = this.tableItems.reduce((accumulator, object) => accumulator + object.Loc_Balance, 0)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.v-data-table-header-mobile {
  display: none;
}
.theme--light.v-data-table th {
  border-top: none !important;
}
.min-width-400 {
  min-width: 400px;
}
</style>
